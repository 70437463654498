import React from "react";
import { Row, Col } from "styled-bootstrap-grid";
import { Badge, Background } from "../index";
import { Colors, AppStoreLink } from "../../config";

export default () => (
  <Background color={Colors.darkblue} className="p-5 text-center">
    <h3 className="txt-6 text-light text-bold">Baixe o aplicativo agora.</h3>
    <strong className="txt-3 text-light text-bold">
      Faça seu cadastro em segundos e aproveite todas as vantagens do e-gorjeta.
    </strong>
    <Row className="mt-5">
      <Col col={12}>
        <Badge
          image="badge-ios.png"
          href={AppStoreLink.ios}
          className="mr-3 mb-3"
        />
        <Badge
          image="badge-android.png"
          href={AppStoreLink.android}
          className="mb-3"
        />
      </Col>
    </Row>
  </Background>
);
