import { domain } from "../config";

class Resource {
  static async all() {
    const data = await fetch(`http://${domain}/api/v1/taxes`);
    return await data.json();
  }

  static async faq() {
    const data = await fetch(`https://egorjeta.s3-sa-east-1.amazonaws.com/static/faq.json`);
    return await data.json();
  }
}

export default Resource;
