import React, { Fragment } from "react";
import { Container, Row, Col } from "styled-bootstrap-grid";
import { Background, Title, Paragraph, Image } from "../../components";
import { Colors } from "../../config";

export default ({ tax, rescue_tax }) => (
  <Fragment>
    <Background image={require("../../assets/blue-background.png")}>
      <Container className="pt-4">
        <Row>
          <Col col={12} sm={12} md={6} className="text-light">
            <Title className="txt-big text-uppercase mt-5 mb-3">
              O e-gorjeta é perfeito para todos os Profissionais.
            </Title>
            <Paragraph className="txt-3 mb-5">
              Vendedores, garçons, entregadores, atendentes, taxistas, caixas,
              freelancers, entre outros... Todos estão aptos a usar a plataforma
              e receber suas gorjetas de forma digital, sem pagar aluguel ou
              mensalidade.
            </Paragraph>
          </Col>
          <Col col={12} md={6}>
            <Image
              src={require("../../assets/professional.png")}
              alt="Profissionais, garçon"
            />
          </Col>
        </Row>
      </Container>
    </Background>
    <Row>
      <Col col={12} md={6} className="pr-0">
        <Image
          src={require("../../assets/woman.png")}
          alt="Melhor opção para o recebimento de gorjetas"
        />
      </Col>
      <Col col={12} md={6} className="d-flex align-items-center">
        <div className="text-secondary txt-2 p-5">
          <h3 className="text-primary text-uppercase text-bold mb-3">
            Melhor opção para o recebimento de gorjetas
          </h3>
          <p>
            Você recebe o valor na sua carteira, gerando saldo que vai
            acumulando e que poderá ser sacado.
          </p>
          <p>
            Receber pelo e-gorjeta é fácil, basta que você tenha o aplicativo
            instalado.
          </p>
          <p>
            Controle em tempo real e monitore todas as suas transações em uma
            interface fácil de usar.
          </p>
        </div>
      </Col>
    </Row>
    <Background color={Colors.blue}>
      <Row>
        <Col col={12} md={6} className="d-flex align-items-center">
          <div className="text-light txt-2 p-5">
            <h3 className="text-uppercase text-bold mb-3">
              Crie ou participe de grupos
            </h3>
            <p>
              No e-gorjeta você pode criar e/ou participar de grupos com outros
              colegas para que a gorjeta seja rateada entre todos os integrantes
              do grupo.
            </p>
          </div>
        </Col>
        <Col col={12} md={6} className="pl-0" hiddenSmDown>
          <Image
            src={require("../../assets/groups.png")}
            alt="Crie ou participe de grupos"
          />
        </Col>
      </Row>
    </Background>
    <Row>
      <Col col={12} md={6} className="pr-0">
        <Image
          src={require("../../assets/notebook-on-legs.png")}
          alt="Acesse sua conta pelo computador"
        />
      </Col>
      <Col col={12} md={6} className="d-flex align-items-center">
        <div className="text-secondary txt-2 p-5">
          <h3 className="text-primary text-uppercase text-bold mb-3">
            Acesse sua conta pelo computador
          </h3>
          <p>
            Você também pode acompanhar as atividades da sua conta no painel
            administrativo aqui na web.
          </p>
        </div>
      </Col>
    </Row>
    <Background color={Colors.darkblue}>
      <Container className="pt-5 pb-5">
        <Row>
          <Col col={12} md={4} className="text-center mt-5">
            <Image
              src={require("../../assets/money.svg")}
              alt="Acesse sua conta pelo computador"
              style={{ width: 138 }}
            />
            <h3 className="text-light text-uppercase text-bold mt-5 mb-4">
              Solicite o resgate do saldo
            </h3>
            <p className="txt-2 text-light">
              Solicitar o resgate do seu saldo é simples. Através do e-gorjeta
              você pode solicitar a transferência para sua conta bancária ou
              pelo Vale Postal Eletrônico (serviço dos Correios).
            </p>
          </Col>
          <Col col={12} md={4} className="text-center mt-5">
            <Image
              src={require("../../assets/clock.svg")}
              alt="Acesse sua conta pelo computador"
              style={{ width: 144 }}
            />
            <h3 className="text-light text-uppercase text-bold mt-5 mb-4">
              Sem prazos para resgate
            </h3>
            <p className="txt-2 text-light">
              O e-gorjeta preza pela sua satisfação, por isso, não tem prazos
              para disponibilizar os seus valores. Logo que estejam disponíveis,
              você pode sacar!
            </p>
          </Col>
          <Col col={12} md={4} className="text-center mt-5">
            <Image
              src={require("../../assets/percent.svg")}
              alt="Acesse sua conta pelo computador"
              style={{ width: 144 }}
            />
            <h3 className="text-light text-uppercase text-bold mt-5 mb-4">
              Tarifas que cabem no bolso
            </h3>
            <p className="txt-2 text-light">
              Para se cadastrar você não paga nada. Consulte abaixo a tabela com
              tarifas para transferência de saldo e manutenção da conta.
            </p>
          </Col>
        </Row>
      </Container>
    </Background>
    <Container className="pt-5 pb-5">
      <h3 className="text-center text-bold">
        Sem taxas e tarifas nas entrelinhas, no e-gorjeta você sabe o que será
        descontado de cada pagamento.
      </h3>
      <table className="table table-bordered mt-5 w-50 ml-auto mr-auto text-center">
        <thead>
          <tr>
            <th className="text-uppercase text-light background-primary">
              Taxa de Transação
            </th>
            <th className="text-uppercase text-light background-primary">
              Tarifa de Retirada
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <big className="text-secondary txt-6">{tax}</big>
            </td>
            <td>
              <big className="text-secondary txt-6">{rescue_tax}</big>
            </td>
          </tr>
        </tbody>
      </table>
      <p className="text-center text-secondary">
        <abbr>*</abbr>Transferência bancária e postal possuem taxas distintas.
      </p>
    </Container>
  </Fragment>
);
