import validate from "validate.js";

class Contact {
  constructor(attrs = {}) {
    this.attrs  = attrs;
    this.errors = {};
  }

  async send() {
    this.validate(this.attrs);
    if (!this.errors) return this.submit();
    return Promise.reject(this.errors);
  }

  validate(attrs) {
    this.errors = validate(attrs, Contact.rules, { format: 'flat', fullMessages: false });
    return this.errors;
  }

  submit() {
    const body = new FormData();

    body.append('name',    this.attrs.name);
    body.append('email',   this.attrs.email);
    body.append('phone',   this.attrs.phone);
    body.append('message', this.attrs.message);

    fetch('/sendmail.php', { method: 'POST', body: body });
  }
}

Contact.rules = {
  name: {
    presence: {
      message: "nome é obrigatório"
    },
    length: {
      minimum: 3,
      message: "nome é muito curto"
    }
  },
  email: {
    presence: {
      message: "e-mail é obrigatório"
    },
    email: {
      message: "e-mail não é válido"
    }
  },
  phone: {
    format: {
      pattern: /^(?:\(?([1-9][0-9])\)?\s?)?(?:((?:9\d|[2-9])\d{3})-?(\d{4}))$/,
      flags: "ig",
      message: "telefone não é válido"
    }
  },
  message: {
    presence: {
      message: "mensagem é obrigatória"
    },
    length: {
      minimum: 10,
      message: "mensagem é muito curta"
    }
  }
};

export default Contact;
