import React from "react";
import _ from "styled-components";

export default props => <Wrapper {...props}>{props.children}</Wrapper>;

const Wrapper = _.section`
  background-color: ${({ color }) => color};
  background-image: url(${({ image }) => image});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
`;
