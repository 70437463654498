import React from "react";
import { Container, Row, Col } from "styled-bootstrap-grid";
import { Link } from "react-router-dom";
import { Brand, Icon, Badge } from "../../components";
import { AppStoreLink, domain } from "../../config";
import _ from "styled-components";

import { mdiFacebook, mdiInstagram } from "@mdi/js";

export default () => (
  <Wrapper>
    <Container>
      <Row>
        <Col col={6}>
          <Brand />
        </Col>
        <Col col={6}>
          <Social className="d-flex justify-content-end">
            <a
              href="https://www.facebook.com/Egorjeta-100962807958321/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon path={mdiFacebook} size={1} color="white" />
            </a>
            <a
              href="https://www.instagram.com/egorjeta/"
              target="_blank"
              rel="noopener noreferrer"
              className="ml-4 mr-4"
            >
              <Icon path={mdiInstagram} size={1} color="white" />
            </a>
          </Social>
        </Col>
      </Row>
      <Line />
      <Row>
        <Col col={12} md={8} className="mt-3">
          <Row>
            <Col col={6} md={4}>
              <Link className="d-block mb-2" to="/">
                Página Inicial
              </Link>
              <Link className="d-block mb-2" to="/dar-gorjeta">
                Como Funciona?
              </Link>
              <Link className="d-block mb-2" to="/receber-gorjeta">
                Profissionais
              </Link>
              <Link className="d-block mb-2" to="/entre-em-contato">
                Entre em Contato
              </Link>
              <Link className="d-block mb-2" to="/perguntas-frequentes">
                Perguntas Frequentes
              </Link>
            </Col>
            <Col col={6} md={4}>
              <Link className="d-block mb-2" to="/politica-de-privacidade">
                Política de Privacidade
              </Link>
              <Link className="d-block mb-2" to="/termos-e-condicoes">
                Termos e Condições
              </Link>
            </Col>
            <Col col={12} md={4}>
              <ExternalLink
                className="d-block mb-2"
                target="_blank"
                rel="noopener noreferrer"
                href={`${domain}/users/login`}
              >
                Login
              </ExternalLink>
              <ExternalLink
                className="d-block mb-2"
                target="_blank"
                rel="noopener noreferrer"
                href={`${domain}/users/registration`}
              >
                Faça seu cadastro
              </ExternalLink>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col col={12} md={8} className="mt-4 mb-3">
          <span className="text-secondary">
            Copyright © 2019 e-gorjeta. Todos os direitos reservados.
          </span>
        </Col>
        <Col col={8} md={4} className="d-flex align-items-center">
          <Badge
            image="badge-ios.png"
            href={AppStoreLink.ios}
            className="mr-3"
          />
          <Badge image="badge-android.png" href={AppStoreLink.android} />
        </Col>
      </Row>
    </Container>
  </Wrapper>
);

const Wrapper = _.footer`
  background-color: black;
  padding: 3rem 0

  a {
    color: white
  }
`;

const Line = _.hr`
  border: none;
  border-top: 1px solid;
  border-color: #555;
  margin: 1.25rem 0
`;

const Social = _.nav``;

const ExternalLink = _.a``;
