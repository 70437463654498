import React from "react";
import ReactDOM from "react-dom";
import "./index.css";

import { BrowserRouter } from "react-router-dom";
import { Layout } from "./components";
import Routes from "./config/routes";
import * as serviceWorker from "./serviceWorker";

const Website = () => (
  <BrowserRouter>
    <Layout>
      <Routes />
    </Layout>
  </BrowserRouter>
);

const root = document.getElementById("root");
root.hasChildNodes()
  ? ReactDOM.hydrate(<Website />, root)
  : ReactDOM.render(<Website />, root);

serviceWorker.unregister();
