import React from "react";
import _ from "styled-components";

export default props => (
  <Link {...props}>
    <Image src={require("../../assets/" + props.image)} alt="E-Gorjeta" />
  </Link>
);

const Link = _.a``;

const Image = _.img`
  max-width: 100%;
  margin-bottom: 1rem
`;
