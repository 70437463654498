import React from "react";
import { Link } from "react-router-dom";
import { Brand } from "../../components";
import { Colors, domain } from "../../config";
import _ from "styled-components";

export default () => (
  <NavBar className="navbar navbar-expand navbar-dark flex-column flex-md-row bd-navbar sticky-top">
    <Link to="/" className="navbar-brand">
      <Brand />
    </Link>
    <List className="navbar-nav flex-row ml-md-auto d-md-flex">
      <Item className="nav-item">
        <Link to="/dar-gorjeta" className="nav-link" title="Como funciona">
          Dar Gorjeta
        </Link>
      </Item>
      <Item className="nav-item">
        <Link to="/receber-gorjeta" className="nav-link">
          Receber Gorjeta
        </Link>
      </Item>
      <Item className="nav-item">
        <ExternalLink
          href={`${domain}/users/registration`}
          target="_blank"
          rel="noopener noreferrer"
          className="nav-link d-none d-sm-block">
          Cadastre-se
        </ExternalLink>
        <ExternalLink
          href={`${domain}/users/login`}
          target="_blank"
          rel="noopener noreferrer"
          className="nav-link d-sm-none">
          Comece agora!
        </ExternalLink>
      </Item>
      <Item className="nav-item d-none d-sm-inline">
        <Button
          target="_blank"
          rel="noopener noreferrer"
          href={`${domain}/users/login`}
          className="btn btn-light d-lg-inline-block mb-3 mb-md-0 ml-md-3">
          Login
        </Button>
      </Item>
    </List>
  </NavBar>
);

const NavBar = _.header`
  background-color: ${Colors.blue};
`;

const List = _.ul``;
const Item = _.li``;
const ExternalLink = _.a``;

const Button = _.a`
  text-transform: uppercase;
  font-weight: bold;
  color: ${Colors.blue}
`;
