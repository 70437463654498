import React, { Fragment } from "react";
import { Container, Row, Col } from "styled-bootstrap-grid";
import { Button, Background, Image } from "../../components";
import { Colors, domain } from "../../config";

export default () => (
  <Fragment>
    <Background color={Colors.blue}>
      <Container className="pt-5 pb-5">
        <Row>
          <Col col={12} sm={12} md={6} className="text-light">
            <h1 className="text-bold txt-5 mb-5">
              Faça parte do movimento que está revolucionando a forma de pagar
              gorjeta.
            </h1>
            <Row className="mb-3">
              <Col col={2} className="d-flex align-items-center">
                <img
                  src={require("../../assets/clock.svg")}
                  alt="Economize tempo"
                  style={{ width: 48 }}
                />
              </Col>
              <Col col={10} className="d-flex align-items-center">
                <strong className="txt-2">Economize tempo</strong>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col col={2} className="d-flex align-items-center">
                <img
                  src={require("../../assets/check.svg")}
                  alt="Transação confiável"
                  style={{ width: 42 }}
                />
              </Col>
              <Col col={10} className="d-flex align-items-center">
                <strong className="txt-2">Transação confiável</strong>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col col={2} className="d-flex align-items-center">
                <img
                  src={require("../../assets/success.svg")}
                  alt="Resolva em instantes"
                  style={{ width: 48 }}
                />
              </Col>
              <Col col={10} className="d-flex align-items-center">
                <strong className="txt-2">Resolva em instantes</strong>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col col={2} className="d-flex align-items-center">
                <img
                  src={require("../../assets/money.svg")}
                  alt="Pague qualquer pessoa, que possua ou não conta na plataforma"
                  style={{ width: 48 }}
                />
              </Col>
              <Col col={10} className="d-flex align-items-center">
                <strong className="txt-2">
                  Pague qualquer pessoa, que possua ou não conta na plataforma
                </strong>
              </Col>
            </Row>
          </Col>
          <Col col={12} md={6}>
            <Image
              src={require("../../assets/session.png")}
              alt="e-gorjeta inicial"
            />
          </Col>
        </Row>
      </Container>
    </Background>
    <Container>
      <Row>
        <Col col={12} className="pt-5 pb-5 text-center">
          <h2 className="text-uppercase text-primary text-bold mb-4">
            Pague gorjetas
          </h2>
          <p className="text-secondary txt-3">
            Faça pagamentos de gorjetas para profissionais através do
            aplicativo. Se o profissional ainda não possuir uma conta, não tem
            problema! Basta indicar o número do CPF do profissional ao destinar
            o pagamento.
          </p>
          <Image
            src={require("../../assets/flux.png")}
            alt="Fluxo de pagamentos"
            className="mt-3 mb-3"
          />
        </Col>
      </Row>
    </Container>
    <Background color={Colors.gray}>
      <Container className="pt-5 pb-5">
        <Row>
          <Col col={12} md={6} className="d-flex align-items-center">
            <article>
              <h3 className="text-bold txt-4 text-primary">
                Como funcionam os pagamentos
              </h3>
              <p className="txt-3 text-secondary">
                Para utilizar o e-gorjeta é necessário cadastrar seu cartão de
                crédito. Com ele, você faz pagamentos que virão posteriormente
                identificados em sua fatura.
              </p>
            </article>
          </Col>
          <Col
            col={12}
            md={6}
            className="d-flex align-items-center justify-content-center"
          >
            <Image
              src={require("../../assets/credit-card.png")}
              alt="Pagamentos com cartão de crédito"
              className="mt-3 mb-3"
            />
          </Col>
        </Row>
      </Container>
    </Background>
    <Container className="pt-5 pb-5">
      <Row>
        <Col col={12} md={6}>
          <Image
            src={require("../../assets/notebook-on-desk.png")}
            alt="Pagamentos online"
            className="mt-3 mb-3"
          />
        </Col>
        <Col
          col={12}
          md={6}
          className="d-flex align-items-center justify-content-center"
        >
          <article>
            <h3 className="text-bold txt-4 text-primary">
              Acesse sua conta pelo computador
            </h3>
            <p className="txt-3 text-secondary mb-5">
              Você também pode acompanhar suas atividades aqui na web, realize o
              login e acesse sua conta.
            </p>
            <Button to={`${domain}/users/login`} rounded>
              Comece agora!
            </Button>
          </article>
        </Col>
      </Row>
    </Container>
    <Background color={Colors.blue}>
      <Container className="pt-5 text-center">
        <h3 className="text-bold txt-5 mt-5 text-uppercase text-light">
          Convide seus amigos
        </h3>
        <p className="text-light txt-3 mb-5">
          Convide quem você conhece para participar do e-gorjeta. Todos os
          profissionais estão aptos a receber gorjetas através da
          plataforma.
        </p>
        <Image
          src={require("../../assets/people.png")}
          alt="Pessoas adoram o e-gorjeta"
        />
      </Container>
    </Background>
  </Fragment>
);
