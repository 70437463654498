export const Colors = {
  blue: "#0386FB",
  darkblue: "#2865bd",
  gray: "#ebebeb"
};

export const AppStoreLink = {
  android: "https://play.google.com/store/apps/details?id=br.com.egorjeta",
  ios: "https://apps.apple.com/us/app/e-gorjeta/id1476431314"
};

export const domain = "http://app.egorjeta.com.br";
