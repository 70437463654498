import React, { Component } from "react";
import { Resource } from "../models";

import { ProfessionalsPage } from "../pages";

class ProfessionalsController extends Component {
  constructor(props) {
    super(props);
    this.state = { tax: 0, rescue_tax: 0 };
  }

  componentDidMount = async () => {
    try {
      const { data } = await Resource.all();
      this.setState({ ...data });
    } catch (err) {
      console.log(err);
    }
  };

  render = () => <ProfessionalsPage {...this.props} {...this.state} />;
}

export default ProfessionalsController;
