import React, { Fragment, useState } from "react";
import { Container, Row, Col } from "styled-bootstrap-grid";
import _ from "styled-components";
import { Background } from "../../components";
import { Colors } from "../../config";

export default ({ data }) => (
  <Fragment>
    <Background color={Colors.gray}>
      <Container className="pt-5 pb-5">
        <h3 className="text-primary text-uppercase text-bold mb-3">
          Perguntas frequentes
        </h3>
        <p>
          Confira perguntas e respostas relacionadas a utilização do e-gorjeta.
        </p>
        <Row>
          <Col col={12} md={8}>
            <FaqList>
              {data.map(e => (
                <Fragment>
                  <Faq key={e.id} title={e.question}>
                    <FaqItem>{e.answer}</FaqItem>
                  </Faq>
                </Fragment>
              ))}
            </FaqList>
          </Col>
        </Row>
      </Container>
    </Background>
  </Fragment>
);

const Faq = ({ title, children }) => {
  const [collapsed, setCollapsed] = useState(false);

  const handleOnToggle = e => {
    e.preventDefault();
    setCollapsed(!collapsed);
  };

  return (
    <div style={{ paddingBottom: 14, paddingTop: 14, position: "relative" }}>
      <FaqLink href="#" onClick={handleOnToggle}>
        <Marker>
          <span className={collapsed ? "minus" : ""}>{collapsed ? "-" : "+"}</span>
        </Marker>
        {title}
      </FaqLink>
      {collapsed && children}
    </div>
  );
};

const Marker = _.div`
  position: absolute;
  right: 15px;
  top: 14px;
  height: 22px;
  width: 22px;
  border 1px solid #333;
  border-radius: 11px;
  
  span {
    position: relative;
    bottom: 8px;
    left: 3px;
    font-size: 23px;
  }

  .minus {
    font-size: 30px;
    line-height: 30px;
    left: 5px;
  }
`;

const FaqList = _.ol`
  display: block;
  margin-left: 0;
  margin-bottom: 0;
  padding-left: 0;
`;

const FaqLink = _.a`
  display: block;
  padding: 0;
  text-decoration: none!important;
  color: #333;
  font-weight: bold;
  font-size: 1rem;
  
  &:hover {
    color: #333;
  }
`;

const FaqItem = _.li`
  list-style-type: none;
  padding: 14px 0 14px 0;
  text-decoration: none!important;
  color: #333;
  font-size: 1rem;
  border-bottom: 1px solid #DBDBDB;
`;
