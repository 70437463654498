import React from "react";
import _ from "styled-components";

export default ({ title, busy = false }) => (
  <Button type="submit" className="btn btn-primary" disabled={busy}>
    {busy ? "Aguarde..." : title}
  </Button>
);

const Button = _.button`
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1.25rem;
  border-radius: 2rem;
  padding: 0.5rem 2rem;
`;
