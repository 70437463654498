import React from "react";
import { Container, Row, Col } from "styled-bootstrap-grid";
import {
  Title,
  Paragraph,
  Form,
  Input,
  TextArea,
  Submit
} from "../../components";

export default ({ params, notice, type, busy, actions }) => (
  <Container>
    <Row>
      <Col col={12} md={6} className="pt-5">
        <div>
          <Title className="text-primary">Entre em contato</Title>
          <Paragraph className="text-secondary txt-3">
            Para entrar em contato com o e-gorjeta basta preencher o
            formulário e enviar sua mensagem. Retornaremos o mais breve
            possível!
          </Paragraph>
        </div>
      </Col>
      <Col col={12} md={6} className="pt-5 pb-5">
        <div className={`alert alert-${type}`} hidden={!notice.length}>
          {notice}
        </div>
        <Form onSubmit={actions.onSubmit}>
          <Input
            name="name"
            id="contact_name"
            label="Nome completo"
            onChange={actions.onChange}
            value={params.name}
            required
          />
          <Input
            name="email"
            id="contact_email"
            label="E-mail"
            onChange={actions.onChange}
            value={params.email}
            required
          />
          <Input
            name="phone"
            id="contact_phone"
            label="Telefone"
            onChange={actions.onChange}
            maxLength={15}
            value={params.phone}
          />
          <TextArea
            name="message"
            id="contact_message"
            label="Escreva sua mensagem"
            onChange={actions.onChange}
            value={params.message}
            required
          />
          <Submit title="Enviar Mensagem" busy={busy} />
        </Form>
      </Col>
    </Row>
  </Container>
);
