import React from "react";
import _ from "styled-components";

export default ({
  type = "btn-primary",
  className,
  rounded,
  to = "/",
  children
}) => (
  <Button
    className={["btn", type, className].join(" ")}
    href={to}
    rounded={rounded}
  >
    {children}
  </Button>
);

const Button = _.a`
  font-weight: bold;
  border-radius: ${({ rounded }) => (rounded ? "3rem" : "0.25rem")};
  padding: ${({ rounded }) => (rounded ? ".375rem 2rem" : ".375rem .75rem")};
  text-transform: uppercase;

  &:hover {
    cursor: pointer;
  }
`;
