import React from "react";
import _ from "styled-components";

import { Header, Download, Footer } from "../../components";

export default ({ children }) => (
  <Wrapper>
    <Header />
    {children}
    <Download />
    <Footer />
  </Wrapper>
);

const Wrapper = _.div`
  overflow-x: hidden;
`;
