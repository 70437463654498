import React, { useEffect } from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import {
  HomeController,
  HowItWorksController,
  ProfessionalsController,
  ContactController,
  TermsController,
  PolicyController,
  FaqController
} from "../controllers";

const Routes = ({ history }) => {
  useEffect(() => {
    const listener = history.listen(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    });

    return () => listener.remove();
  }, [history]);

  return (
    <Switch>
      <Route exact path="/" component={HomeController} />
      <Route exact path="/dar-gorjeta" component={HowItWorksController} />
      <Route exact path="/receber-gorjeta" component={ProfessionalsController} />
      <Route exact path="/entre-em-contato" component={ContactController} />
      <Route exact path="/termos-e-condicoes" component={TermsController} />
      <Route exact path="/politica-de-privacidade" component={PolicyController} />
      <Route exact path="/perguntas-frequentes" component={FaqController} />
    </Switch>
  );
};

export default withRouter(Routes);
