import React from "react";

export default () => (
  <div className="terms container p-5 text-justify">
    <h1 class="code-line" data-line-start="0" data-line-end="1">
      Termos e Condições
    </h1>
    <p class="has-line-data" data-line-start="1" data-line-end="2">
      Por favor, leia com atenção os termos e condições. Ao se cadastrar no{" "}
      <a
        href="http://egorjeta.com.br/"
        target="_blank"
        rel="noopener noreferrer"
      >
        egorjeta.com.br
      </a>{" "}
      você ESTÁ DE ACORDO COM AS CONDIÇÕES E TERMOS do Website e do APP. Note
      que a recusa destes Termos impedirá que você utilize nossos serviços.
    </p>
    <ol>
      <li class="has-line-data" data-line-start="2" data-line-end="6">
        SERVIÇOS OFERECIDOS
        <br />
        1.1. Este TERMO se aplica para regular o uso do serviço oferecido pelo{" "}
        <a
          href="http://egorjeta.com.br/"
          target="_blank"
          rel="noopener noreferrer"
        >
          egorjeta.com.br
        </a>{" "}
        aos USUÁRIOS, qual seja, intermediar o pagamento e/ou recebimento de
        gorjetas.
        <br />
        1.2. O serviço do{" "}
        <a
          href="http://egorjeta.com.br/"
          target="_blank"
          rel="noopener noreferrer"
        >
          egorjeta.com.br
        </a>{" "}
        consiste, portanto, em aproximar, através do nosso site e/ou app, os
        PAGADORES e os RECEBEDORES cadastrados, possibilitando que os USUÁRIOS
        encaminhem aos PROFISSIONAIS pagamentos de gorjetas, bem como, sendo
        essa a opção dos USUÁRIOS, receber pagamentos on-line, tornando-se um
        PROFISSIONAL.
        <br />
        1.3. Desde logo fica esclarecido ao USUÁRIO - o qual se declara ciente -
        que o serviço oferecido pelo{" "}
        <a
          href="http://egorjeta.com.br/"
          target="_blank"
          rel="noopener noreferrer"
        >
          egorjeta.com.br
        </a>{" "}
        se relaciona apenas à intermediação (com opção de pagamento on-line) do
        pagamento de gorjetas, NÃO abarcando: o serviço prestado pelo
        PROFISSIONAL, salários, encargos, qualquer obrigação trabalhista ou
        fiscal, qualquer situação referente aos produtos servidos ou prestados
        pelo PROFISSIONAL, quaisquer reclamos acerca de problemas decorrentes de
        vício, defeito ou inexecução da feitura, preparo e entrega de produtos
        servidos ou realizados pelo PROFISSIONAL.
      </li>
      <li class="has-line-data" data-line-start="6" data-line-end="10">
        CADASTRO
        <br />
        2.1. O USUÁRIO, para utilizar os serviços acima descritos, deverá ter
        capacidade jurídica para atos civis e deverá, necessariamente, prestar
        as informações exigidas no CADASTRO, assumindo integralmente a
        responsabilidade (inclusive cível e criminal) pela exatidão e veracidade
        das informações fornecidas no CADASTRO, que poderá ser verificado, a
        qualquer momento, pelo{" "}
        <a
          href="http://egorjeta.com.br/"
          target="_blank"
          rel="noopener noreferrer"
        >
          egorjeta.com.br
        </a>{" "}
        .<br />
        2.1.1. Em caso de informações incorretas, inverídicas ou não
        confirmadas, bem assim na hipótese da negativa em corrigi-las ou enviar
        documentação que comprove a correção, o EGORJETA se reserva o direito de
        não concluir o cadastramento em curso ou, ainda, de bloquear o cadastro
        já existente, impedindo o USUÁRIO de utilizar os serviços on-line até
        que, a critério do{" "}
        <a
          href="http://egorjeta.com.br/"
          target="_blank"
          rel="noopener noreferrer"
        >
          egorjeta.com.br
        </a>{" "}
        , a situação de anomalia esteja regularizada. O{" "}
        <a
          href="http://egorjeta.com.br/"
          target="_blank"
          rel="noopener noreferrer"
        >
          egorjeta.com.br
        </a>{" "}
        se reserva o direito de impedir, a seu critério, novos CADASTROS, ou
        cancelar os já efetuados, em caso de ser detectada anomalia que, em sua
        análise, seja revestida de gravidade ou demonstre tentativa deliberada
        de burlar as regras aqui descritas, obrigatórias para todos os USUÁRIOS.
        Também agirá o{" "}
        <a
          href="http://egorjeta.com.br/"
          target="_blank"
          rel="noopener noreferrer"
        >
          egorjeta.com.br
        </a>{" "}
        de tal forma caso verifique descumprimento, pelo USUÁRIO, de qualquer
        obrigação prevista no presente TERMO.
        <br />
        2.2. Efetuado, com sucesso, o CADASTRO, o USUÁRIO terá acesso aos
        serviços por meio de login e senha, dados esses que se compromete a não
        divulgar a terceiros, ficando sob sua exclusiva responsabilidade
        qualquer solicitação de serviço que seja feita com o uso de login e
        senha de sua titularidade.
      </li>
      <li class="has-line-data" data-line-start="10" data-line-end="14">
        OBRIGAÇÕES DO USUÁRIO
        <br />
        3.1. Efetuado com sucesso o CADASTRO do USUÁRIO, este se obriga a não
        divulgar a terceiros login e senha de acesso, nem permitir o uso de tais
        informações por terceiros, responsabilizando-se pelas consequências do
        uso de login e senha de sua titularidade.
        <br />
        3.2. É obrigação do USUÁRIO fornecer informações cadastrais totalmente
        verídicas e exatas, responsabilizando-se exclusiva e integralmente (em
        todas as searas jurídicas) por todo o conteúdo por si informado no item
        CADASTRO, mantendo atualizado e confirmado o endereço para entrega dos
        produtos encomendados.
        <br />
        3.3. O USUÁRIO concorda com o uso das informações de avaliações e
        feedbacks do serviços dos PROFISSIONAIS e do EGORJETA, conforme descrito
        nos TERMOS DE PRIVACIDADE do EGORJETA.
      </li>
      <li class="has-line-data" data-line-start="14" data-line-end="16">
        OBRIGAÇÕES DO PROFISSIONAL
        <br />
        4.1. Efetuado com sucesso o CADASTRO do USUÁRIO na categoria
        PROFISSIONAL, este se obriga a aceitar as políticas financeiras do
        EGORJETA, no que concerne aos pagamentos e/ou descontos de custo com as
        operações financeiras realizadas para efetivar o recebimento e o
        posterior pagamento de GORJETAS para os PROFISSIONAIS, bem como aceita
        os prazos de pagamento pré-estabelecidos, conforme a modalidade de
        pagamento e recebimento vigentes, e aceita a taxa de serviço cobrada
        pelo EGORJETA.
      </li>
      <li class="has-line-data" data-line-start="16" data-line-end="20">
        OBRIGAÇÕES DO{" "}
        <a
          href="http://egorjeta.com.br/"
          target="_blank"
          rel="noopener noreferrer"
        >
          egorjeta.com.br
        </a>
        <br />
        5.1. Disponibilizar no site{" "}
        <a
          href="http://egorjeta.com.br/"
          target="_blank"
          rel="noopener noreferrer"
        >
          egorjeta.com.br
        </a>{" "}
        e nos Apps IOS e ANDROID espaço virtual que permita ao USUÁRIO
        devidamente cadastrado efetivar pagamentos de GORJETAS aos PROFISSIONAIS
        e, também, disponibilizar ao USUÁRIO meios de pagamento do valor da
        GORJETA on-line.
        <br />
        5.2. Disponibilizar no site{" "}
        <a
          href="http://egorjeta.com.br/"
          target="_blank"
          rel="noopener noreferrer"
        >
          egorjeta.com.br
        </a>{" "}
        e nos Apps IOS e ANDROID espaço virtual que permita ao PROFISSIONAL
        devidamente cadastrado efetivar o recebimento de GORJETAS, e também,
        disponibilizar ao PROFISSIONAL meios de recebimento do valor das
        GORJETAS on-line disponibilizadas para si.
        <br />
        5.3. Proteger, por meio de armazenamento em servidores ou quaisquer
        outros meios magnéticos de alta segurança, a confidencialidade de todas
        as informações e cadastros relativos aos USUÁRIOS, assim como valores
        atinentes às operações financeiras advindas da operacionalização dos
        serviços previstos no presente TERMO. Contudo, não responderá pela
        reparação de prejuízos que possam ser derivados de apreensão e cooptação
        de dados por parte de terceiros que, rompendo os sistemas de segurança,
        consigam acessar essas informações.
      </li>
      <li class="has-line-data" data-line-start="20" data-line-end="23">
        MODIFICAÇÕES DESTE TERMO
        <br />
        6.1. O presente TERMO DE USO poderá, a qualquer tempo, ter seu conteúdo,
        ou parte dele, modificados para adequações e inserções, tudo com vistas
        ao aprimoramento dos serviços disponibilizados.
        <br />
        6.2. As novas condições entrarão em vigência assim que sua veiculada no
        site, sendo possível ao USUÁRIO manifestar oposição a quaisquer dos
        termos modificados, desde que o faça por escrito, através do site{" "}
        <a
          href="http://egorjeta.com.br/"
          target="_blank"
          rel="noopener noreferrer"
        >
          egorjeta.com.br
        </a>{" "}
        , o que gerará o cancelamento de seu CADASTRO.
      </li>
      <li class="has-line-data" data-line-start="23" data-line-end="25">
        CANAL DE COMUNICAÇÃO
        <br />
        7.1. Para estabelecer contato entre{" "}
        <a
          href="http://egorjeta.com.br/"
          target="_blank"
          rel="noopener noreferrer"
        >
          egorjeta.com.br
        </a>{" "}
        e o USUÁRIO fica disponibilizado o endereço eletrônico deste link, sendo
        certo que o USUÁRIO se obriga, igualmente, a manter em seu cadastro
        endereço eletrônico atual por intermédio do qual se farão as
        comunicações a ele dirigidas pelo{" "}
        <a
          href="http://egorjeta.com.br/"
          target="_blank"
          rel="noopener noreferrer"
        >
          egorjeta.com.br
        </a>{" "}
        , desde logo emprestando-se validade jurídica e efetividade a esse meio
        eletrônico de troca de informações recíprocas.
      </li>
      <li class="has-line-data" data-line-start="25" data-line-end="27">
        ACEITAÇÃO DO TERMO DE USO
        <br />
        8.1. O USUÁRIO declara ter lido, entendido e que aceita todas as regras,
        condições e obrigações estabelecidas no presente TERMO.
      </li>
      <li class="has-line-data" data-line-start="27" data-line-end="30">
        FORO DE ELEIÇÃO
        <br />
        9.1. As partes elegem como competente para dirimir eventuais
        controvérsias que venham a surgir da interpretação e do cumprimento do
        presente TERMO o foro da Comarca do Curitiba - PR
        <br />
      </li>
    </ol>
    <p>Última atualização: 03 de setembro de 2019.</p>
  </div>
);
