import React, { Fragment } from "react";
import { Container, Row, Col } from "styled-bootstrap-grid";
import { Link } from "react-router-dom";
import { Button, Background, Image, Title, Paragraph } from "../../components";
import { Colors, domain } from "../../config";

export default () => (
  <Fragment>
    <Container>
      <section className="pt-4 pb-4 text-center">
        <strong className="txt-3 mr-4 d-block d-sm-inline mb-3">
          Pague e receba gorjetas em ambiente digital.
        </strong>
        <Button to="/download" rounded>
          Baixe Agora
        </Button>
      </section>
    </Container>
    <Background color={Colors.blue}>
      <Container className="pt-4">
        <Row>
          <Col col={12} sm={12} md={5} className="text-light">
            <Title className="txt-big text-uppercase mt-5 mb-3">
              Sua gorjeta agora é digital!
            </Title>
            <Paragraph className="txt-3 mb-5">
              Com o aplicativo e-gorjeta você transfere o valor da gorjeta para
              uma pessoa ou um grupo de pessoas. Crie uma conta gratuita e pague
              em um instante!
            </Paragraph>
          </Col>
          <Col col={12} md={7}>
            <Image
              src={require("../../assets/garcon.png")}
              alt="Profissionais, garçon"
            />
          </Col>
        </Row>
      </Container>
    </Background>
    <Container>
      <Row>
        <Col col={12} sm={12} md={6} className="d-flex align-items-end">
          <Image
            src={require("../../assets/home-mobile.png")}
            alt="Aplicativo e-gorjeta"
          />
        </Col>
        <Col col={12} sm={12} md={6}>
          <h2 className="text-primary text-bold mt-5 pl-5">
            Faça e receba pagamentos de gorjetas em ambiente digital feito
            exclusivamente pra você
          </h2>
          <Image
            src={require("../../assets/home-activity.png")}
            alt="Aplicativo e-gorjeta, home activity"
            className="mt-2 mb-2"
          />
          <p className="text-secondary txt-2 pl-5">
            O e-gorjeta trabalha de forma simples e digital, sem a intervenção
            de um estabelecimento! Em tempos de cartões eletrônicos, o e-gorjeta
            moderniza e facilita a tradição de pagar gorjetas.
          </p>
        </Col>
      </Row>
    </Container>
    <Background
      image={require("../../assets/restaurant.png")}
      className="pt-5"
      style={{ height: 400 }}>
      <Container>
        <Row>
          <Col col={12} md={7}>
            <Image
              src={require("../../assets/people-activity.png")}
              style={{ marginTop: "135px" }}
            />
          </Col>
        </Row>
      </Container>
    </Background>
    <Container>
      <Row>
        <Col col={12} md={6} mdOffset={7} className="pt-5 pb-5">
          <h2 className="text-primary text-bold mt-3 mb-5">
            Crie uma conta com o seu perfil e comece a usar. É gratuito.
          </h2>
          <p className="text-secondary txt-2 mb-4">
            É simples e rápido, em poucos segundos você efetua o cadastro pelo
            aplicativo. Quer jeito mais fácil de receber gorjetas?
          </p>
          <Button to={`${domain}/users/login`} rounded>
            Comece agora!
          </Button>
        </Col>
      </Row>
    </Container>
    <Background color={Colors.blue} className="p-2 p-md-5">
      <h3 className="txt-6 text-center text-light m-5 text-bold">
        Quer saber melhor como funciona?
        <Link to="/dar-gorjeta" className="ml-2 text-light">
          Clique aqui
        </Link>
      </h3>
    </Background>
  </Fragment>
);
