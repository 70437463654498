import React from "react";
import _ from "styled-components";

export default ({ id, name, label, onChange, value, required }) => (
  <Wrapper className="form-group">
    <Label htmlFor={id}>
      {label}{" "}
      <abbr hidden={!required} title="Obrigatório">
        *
      </abbr>
    </Label>
    <Input
      name={name}
      id={id}
      rows="3"
      className="form-control"
      onChange={onChange}
      value={value}
    />
  </Wrapper>
);

const Wrapper = _.div``;
const Label = _.label``;
const Input = _.textarea`
  min-height: 3rem;
  max-height: 5rem;
  border: 0.15rem solid #e9e9e9;
  font-weight: bold;
  font-size: 1.25rem;
`;
