import React from "react";

export default () => (
  <div className="terms container p-5 text-justify">
    <h1 class="code-line" data-line-start="0" data-line-end="1">
      Política de Privacidade
    </h1>
    <p class="has-line-data" data-line-start="1" data-line-end="2">
      Data de atualização: 19 de setembro de 2019.
    </p>
    <p class="has-line-data" data-line-start="3" data-line-end="4">
      Nós utilizamos os seus dados como parte da nossa missão de proporcionar um
      serviço cada vez melhor. Essa política descreve como obtemos, armazenamos,
      utilizamos e compartilhamos as suas informações.
    </p>
    <p class="has-line-data" data-line-start="5" data-line-end="7">
      A proteção dos seus dados e da sua privacidade são muito importantes para
      o E-GORJETA. Portanto, recomendamos que você conheça melhor as nossas
      práticas e, em caso de dúvidas, entre em contato conosco.
      <br />
      Você consente, de forma livre e expressa, que seus dados e informações
      sejam coletados, armazenados, tratados e compartilhados, conforme as
      regras dispostas nesta Política de Privacidade.
    </p>
    <p class="has-line-data" data-line-start="8" data-line-end="10">
      Ao se cadastrar no E-GORJETA, o Usuário garante de forma expressa que é
      plenamente capaz, nos termos da legislação vigente, para exercer e gozar
      de todos os Serviços. Ademais, os Usuários menores de 18 anos deverão
      obter o consentimento expresso de seus pais, tutores ou representantes
      legais para utilizar os Serviços, conforme as disposições dos Termos e da
      Política de Privacidade do E-GORJETA. Os pais, tutores ou representantes
      legais serão plenamente responsáveis também no caso de acesso ao E-GORJETA
      por parte de crianças e adolescentes, sem a devida obtenção de autorização
      prévia. Cabe a eles a integral responsabilidade pela fiscalização das
      atividades e conduta dos respectivos menores sob sua tutela, bem como
      ciência da integralidade dos presentes Termos.
      <br />
      Informamos ainda a você que o serviço prestado pelo E-GORJETA não é
      direcionado a adolescentes e crianças menores de 13 anos.
    </p>
    <p class="has-line-data" data-line-start="11" data-line-end="15">
      <strong>Quais tipos de informações coletamos?</strong>
      <br />
      Ao utilizar o Website ou Aplicativo de propriedade do E-GORJETA, nós
      coletamos algumas informações importantes para que possamos prestar um
      serviço adequado para você. Abaixo detalhamos quais são essas informações.
      <br />
      Informações que você nos fornece
      <br />
      Quando você se inscrever para ser um usuário registrado do E-GORJETA,
      poderemos obter uma série de informações sobre você, tais como: seu nome,
      data de nascimento, CPF, endereço eletrônico, endereço de correspondência,
      senha, número de telefone e preferências de contato.
    </p>
    <p class="has-line-data" data-line-start="16" data-line-end="17">
      Para criar o seu cadastro no Website ou Aplicativo do E-GORJETA, você pode
      ainda usar a sua conta em redes sociais. Por exemplo, ao utilizar o
      Facebook para se inscrever no nosso serviço, você estará permitindo que o
      E-GORJETA acesse as informações pessoais em sua conta do Facebook, tais
      como seu nome, e-mail, gênero, idade e telefone (caso o tenha cadastrado
      no Facebook). As informações que poderemos obter, nesse caso, dependem das
      suas configurações de privacidade junto ao serviço de rede social.
    </p>
    <p class="has-line-data" data-line-start="18" data-line-end="19">
      Você tem a opção de importar seus contatos pessoais (incluindo as
      informações disponíveis no serviço de rede social) para o nosso serviço,
      permitindo assim que você localize seus amigos e seus amigos o localizem.
    </p>
    <p class="has-line-data" data-line-start="20" data-line-end="21">
      Além dessas informações iniciais de cadastro, você pode ainda nos fornecer
      dois tipos de informações: (a) dados de pagamento (apenas nos casos em que
      o pagamento é online); (b) informações de recebimento e © informações dos
      seus dispositivos. Abaixo detalhamos essas informações.
    </p>
    <p class="has-line-data" data-line-start="22" data-line-end="26">
      <strong>a. Informações de pagamento</strong>
      <br />
      Ao fazer o seu pagamento pelo E-GORJETA, é possível fazer o pagamento
      diretamente no nosso aplicativo.
      <br />
      Ao escolher fazer o pagamento direto no nosso Website ou Aplicativo, você
      poderá nos fornecer os seus dados de pagamento, como cartão de crédito e
      meios de pagamento parceiros.
      <br />
      Nós usamos esses dados para poder confirmar a sua ordem de pagamento e
      garantir que não ocorram fraudes por meio dos nossos Serviços.
    </p>
    <p class="has-line-data" data-line-start="27" data-line-end="29">
      <strong>b. Informações de recebimento</strong>
      <br />
      Para poder retirar seu saldo, você poderá nos fornecer dados bancário,
      como Banco, agência e número de conta.
    </p>
    <p class="has-line-data" data-line-start="30" data-line-end="33">
      <strong>c. Informações dos seus dispositivos</strong>
      <br />
      Além dessas informações que você mesmo nos fornece, nós podemos coletar
      algumas informações automaticamente sobre os dispositivos a partir dos
      quais você acessa o E-GORJETA.
      <br />
      Dentre essas informações estão endereços IP, tipo de navegador e idioma,
      provedor de serviços de Internet (ISP), páginas de consulta e saída,
      sistema operacional, informações sobre data e horário, dados sobre a
      sequência de cliques, fabricante do dispositivo, operadora, modelo, redes
      Wi-Fi, número de telefone, entre outras que poderão ser coletadas pelo
      E-GORJETA.
    </p>
    <p class="has-line-data" data-line-start="34" data-line-end="37">
      <strong>Informações não-pessoais</strong>
      <br />
      Nós também podemos obter informações não-pessoais, ou seja, dados que não
      permitem a associação direta com qualquer pessoa especificamente. São
      exemplos de dados não-pessoais a idade do indivíduo, preferencias
      individuais, idioma, CEP e código de área.
      <br />
      Nós também poderemos obter informações sobre as atividades dos usuários no
      nosso Website ou Aplicativo. Essas informações são agregadas e usadas para
      nos ajudar a fornecer informações para os nossos clientes e entender quais
      partes dos nossos serviços atraem mais interesse dos usuários. Dados
      agregados são considerados informações não-pessoais para fins desta
      Política de Privacidade.
    </p>
    <p class="has-line-data" data-line-start="38" data-line-end="40">
      Se combinarmos informações não-pessoais com informações pessoais, a
      informação combinada será tratada como informação pessoal enquanto essa
      informação permanecer combinada.
      <br />
      Como utilizamos essas informações?
    </p>
    <p class="has-line-data" data-line-start="41" data-line-end="44">
      Promover, melhorar e desenvolver nossos serviços
      <br />
      Podemos usar essas informações coletadas para nos ajudar a desenvolver,
      oferecer e melhorar os nossos produtos e serviços destinados a você,
      existentes ou serem criados.
      <br />
      Também poderemos usar suas informações pessoais para fins internos, tais
      como auditoria, análise de dados e pesquisas para aprimorar os produtos,
      serviços e comunicações com os clientes, e geração de análises
      estatísticas com respeito ao uso dos nossos serviços, incluindo tendências
      de consumo.
    </p>
    <p class="has-line-data" data-line-start="45" data-line-end="48">
      <strong>Comunicação</strong>
      <br />
      Periodicamente poderemos usar suas informações pessoais para enviar avisos
      e notificações importantes, como comunicados sobre pagamentos,
      recebimentos, alterações em prazos, condições e políticas. Como estas
      informações são importantes para a sua interação com o E-GORJETA, você não
      poderá optar por não receber esse tipo de comunicação, já que são
      inerentes ao uso do serviço.
      <br />
      Segurança
    </p>
    <p class="has-line-data" data-line-start="49" data-line-end="50">
      Nós podemos também utilizar esses dados coletados para aprimorar a
      segurança e melhorar os serviços e as ofertas do E-GORJETA destinadas a
      você. Dessa forma, podemos analisar e solucionar problemas técnicos, bem
      como identificar e coibir fraudes na utilização do nosso Serviço.
    </p>
    <p class="has-line-data" data-line-start="51" data-line-end="53">
      <strong>Marketing</strong>
      <br />
      Nós poderemos usar suas informações pessoais para enviar publicidade, bem
      como outros materiais promocionais voltados ao marketing de nossos
      serviços e de novidades para você, o que inclui campanhas digitais (tais
      como marketing direcionado em redes sociais e notificações push) e não
      digitais (como divulgação em radiodifusão, outdoors, panfletos, entre
      outros).
    </p>
    <p class="has-line-data" data-line-start="54" data-line-end="56">
      Nesse caso, você pode optar por não receber mais e-mails de marketing a
      qualquer momento, alterando as suas preferências.
      <br />
      Além disso, poderemos compartilhar as informações com parceiros do
      E-GORJETA, para fins de desenvolver campanhas de marketing mais relevantes
      para interessados nos produtos do E-GORJETA. O E-GORJETA somente
      compartilhará dados com parceiros que possuírem política de privacidade
      que ofereça níveis compatíveis de proteção àquele oferecido por esta
      política.
    </p>
    <p class="has-line-data" data-line-start="57" data-line-end="59">
      <strong>Promoções</strong>
      <br />
      Se você participar de um concurso, competição ou qualquer outra modalidade
      de promoção, poderemos usar as informações que você fornecer para
      administrar esses programas.
    </p>
    <p class="has-line-data" data-line-start="60" data-line-end="62">
      <strong>Dados não-pessoais</strong>
      <br />
      Nós poderemos usar informações não-pessoais para a consecução das
      finalidades previstas nessa Política de Privacidade.
    </p>
    <p class="has-line-data" data-line-start="63" data-line-end="65">
      <strong>Como utilizamos cookies e outras tecnologias?</strong>
      <br />O E-GORJETA se utiliza de tecnologias como cookies, pixel tags,
      armazenamento local ou outros identificadores, tanto de dispositivos
      móveis ou não, ou tecnologias semelhantes (“cookies e outras
      tecnologias”), para uma variedade de funções. Essas tecnologias nos ajudam
      a autenticar a sua conta, promover e aperfeiçoar os serviços do E-GORJETA,
      personalizar a sua experiência e avaliar a eficácia da nossa comunicação e
      publicidade.
    </p>
    <p class="has-line-data" data-line-start="66" data-line-end="70">
      <strong>O que são essas tecnologias?</strong>
      <br />
      Cookies são pequenos arquivos armazenados no seu navegador, celular ou
      outro dispositivo.
      <br />
      Pixel tags (ou GIFs limpos, web beacons ou pixels) são pequenos blocos de
      código em uma página da web que permitem que elas realizem ações como ler
      e armazenar cookies e transmitir informações para o E-GORJETA. A conexão
      resultante pode incluir informações como o endereço de IP de um
      dispositivo, a hora em que uma pessoa visualizou o pixel, um identificador
      associado ao navegador ou dispositivo e o tipo de navegador em uso.
      <br />
      Identificadores de dispositivo mobile são códigos que possibilitam a
      identificação do seu dispositivo mobile, seja de maneira persistente ou
      transitória, tais como o ID de Publicidade ou o ID de seu sistema
      operacional.
    </p>
    <p class="has-line-data" data-line-start="71" data-line-end="72">
      Armazenamento local é uma tecnologia padrão da indústria que permite que
      um website ou aplicativo armazene e recupere dados do computador, celular
      ou outro dispositivo de uma pessoa. Exemplos desse mecanismo são HTML5 e
      caching.
    </p>
    <p class="has-line-data" data-line-start="73" data-line-end="75">
      <strong>Como funcionam?</strong>
      <br />
      Cookies e outras tecnologias nos ajudam a melhor prestar os nossos
      serviços a você, bem como a entender melhor o seu comportamento, nos
      dizendo quais partes de nosso Website ou Aplicativo as pessoas visitaram
      e, ainda, facilitam a medição da eficácia da publicidade e das pesquisas
      na web.
    </p>
    <p class="has-line-data" data-line-start="76" data-line-end="78">
      Nós tratamos as informações obtidas por meio de cookies e outras
      tecnologias como informações não-pessoais. Entretanto, na medida em que o
      endereço protocolo de internet (endereço IP) ou identificadores
      semelhantes sejam considerados informações pessoais pela lei local
      aplicável, nós também trataremos esses identificadores como informação
      pessoal.
      <br />
      Da mesma forma, na medida em que a informação não-pessoal seja combinada
      com informação pessoal, nós tratamos a informação combinada como
      informação pessoal para os fins desta Política de Privacidade.
    </p>
    <p class="has-line-data" data-line-start="79" data-line-end="81">
      Você pode gerenciar as suas preferências de cookies e outras tecnologias
      semelhantes a partir da configuração de seu navegador ou dispositivo,
      recusando ou excluindo determinados cookies e outros tecnologias. Contudo,
      isso poderá comprometer a prestação dos nossos serviços ou impedir o
      funcionamento de determinadas funcionalidades que os integram.
      <br />
      Os nomes específicos dos cookies e outras tecnologias usados podem variar
      à medida em que os nossos serviços são melhorados e atualizados, mas eles
      geralmente se restringem às categorias de uso abaixo:
    </p>
    <h3 class="code-line" data-line-start="82" data-line-end="83">
      Categorias de uso Exemplos
    </h3>
    <p class="has-line-data" data-line-start="83" data-line-end="86">
      <strong>Autenticação</strong>
      <br />
      Indica que você está conectado, para que seja possível oferecê-lo os
      recursos apropriados, bem como para entender como você usa nossos
      serviços.
      <br />
      Segurança e integridade Ajuda a manter a segurança dos nossos serviços,
      oferecendo suporte ou ativando recursos de segurança e ajudando a detectar
      atividades que violam nossos Termos e Condições de Uso e Política de
      Privacidade. Por exemplo, auxilia a proteger a sua conta contra acessos
      indesejados.
    </p>
    <p class="has-line-data" data-line-start="87" data-line-end="88">
      Anúncios, ideias e medição Ajuda a analisar comportamentos de navegação, a
      fim de veicular e medir anúncios, torná-los mais relevantes para você e
      analisar o conteúdo e uso de produtos e serviços. Por exemplo, é possível
      usar um cookie ou outra tecnologia para descobrir se alguém que tenha sido
      exposto a um anúncio realiza um pagamento relacionado ao anúncio.
    </p>
    <p class="has-line-data" data-line-start="89" data-line-end="92">
      Recursos e serviços Ajuda a fornecer produtos e serviços. Por exemplo,
      quando você vê ou interage com conteúdo dos nossos serviços, é possível
      oferecer a você outros plug-ins sociais, diferentes experiências e
      conteúdo personalizado.
      <br />
      Desempenho Ajuda a proporcionar a melhor experiência possível, por
      exemplo, ajudando a rotear o tráfego entre servidores e perceber com que
      velocidade o serviço do E-GORJETA é carregado para diferentes pessoas.
      Ocasionalmente, poderão ser armazenadas informações no seu navegador ou
      dispositivo para que os recursos em uso carreguem e respondam rapidamente.
      <br />
      Análises e pesquisas Ajuda a entender, aprimorar e pesquisar produtos e
      serviços quando você acessa o E-GORJETA.
    </p>
    <p class="has-line-data" data-line-start="93" data-line-end="97">
      <strong>Promover uma experiência personalizada</strong>
      <br />
      O E-GORJETA e os seus parceiros também usam cookies e outras tecnologias
      para memorizar informações pessoais quando você usa o nosso Website ou
      Aplicativo. A nossa meta nesses casos é fazer com que a sua experiência
      com o E-GORJETA seja mais conveniente e personalizada.
      <br />
      Por exemplo, saber o seu primeiro nome nos permite dar boas-vindas a você
      na próxima vez em que você acessar o E-GORJETA. Conhecer seu país e idioma
      nos permite viabilizar a você uma experiência de compras personalizada e
      mais útil. Saber que você adquiriu determinado produto ou usou um
      determinado serviço permite fazer com que sua publicidade e comunicações
      de e-mail sejam mais relevantes para os seus interesses.
      <br />
      Nessa linha, podemos também usar essas informações para classificar os
      usuários dentro da rede E-GORJETA, identificando os usuários a partir de
      eventos realizados, como o primeiro pagamento ou recebimento ou o download
      do nosso aplicativo, e a partir de determinadas características de seu
      perfil, como usuários que realizam mais de 4 pagamentos/recebimentos por
      mês.
    </p>
    <p class="has-line-data" data-line-start="98" data-line-end="101">
      <strong>Avaliar a eficácia da nossa comunicação e publicidade</strong>
      <br />
      Nós usamos essas informações também para entender e analisar tendências,
      administrar os nossos serviços, aprender sobre o comportamento do usuário
      e obter informações demográficas sobre a nossa base de usuários de maneira
      geral. Em algumas de nossas mensagens de e-mail, nós usamos uma “URL
      click-through” (endereço externo) vinculada ao conteúdo do E-GORJETA.
      Quando os clientes clicam em uma dessas URLs, os usuários são enviados
      para um servidor diferente antes de chegarem à página de destino no nosso
      serviço.
      <br />
      Nós monitoramos esses dados de click-through para entender o interesse em
      determinados tópicos e avaliar a eficácia das comunicações com os nossos
      clientes. Se você preferir não ser monitorado dessa maneira, não clique em
      texto ou links contidos em mensagens de e-mail enviadas pelo E-GORJETA.
    </p>
    <p class="has-line-data" data-line-start="102" data-line-end="104">
      Os pixel tags nos permitem enviar mensagens de e-mail em formatos que os
      usuários possam ler e nos dizer se o e-mail foi aberto ou não. Nós podemos
      usar essas informações para reduzir ou eliminar as mensagens enviadas aos
      usuários.
      <br />
      Tecnologia de terceiros
    </p>
    <p class="has-line-data" data-line-start="105" data-line-end="106">
      Além dos cookies e outras tecnologias do E-GORJETA, nós podemos permitir
      que terceiros contratados pelo E-GORJETA utilizem cookies e outras
      tecnologias de sua propriedade para identificar seu navegador e
      dispositivo, de modo a lhe oferecer publicidade direcionada do E-GORJETA
      quando você acessa websites ou aplicativos de terceiros.
    </p>
    <p class="has-line-data" data-line-start="107" data-line-end="108">
      Esses terceiros podem também fornecer ao E-GORJETA informações acerca do
      desempenho das campanhas de marketing desenvolvidas.
    </p>
    <p class="has-line-data" data-line-start="109" data-line-end="110">
      Algumas dessas empresas podem utilizar cookies e outras tecnologias
      próprios nos serviços do E-GORJETA, tais como: Facebook Google Analytics e
      Double Click.
    </p>
    <p class="has-line-data" data-line-start="111" data-line-end="114">
      <strong>
        Como as informações são armazenadas? Onde as informações são
        armazenadas?
      </strong>
      <br />
      As informações que coletamos de você são armazenadas em serviços de nuvem
      confiáveis de parceiros que podem estar localizados no Brasil ou em outros
      países que ofereçam serviço de armazenamento de nuvem confiáveis e
      usualmente utilizados por empresas de tecnologia, tais como Estados Unidos
      da América (EUA) e em países da América Latina e da Europa.
      <br />
      Na contratação desses serviços, o E-GORJETA sempre busca empresas que
      empregam alto nível de segurança no armazenamento de suas informações,
      estabelecendo contratos que não violam as definições de privacidade
      previstas nessa Política.
    </p>
    <p class="has-line-data" data-line-start="115" data-line-end="118">
      <strong>Por quanto tempo as informações são armazenadas?</strong>
      <br />
      O E-GORJETA armazena as suas informações durante o período necessário para
      as finalidades apresentadas nos Termos e Condições de Uso do E-GORJETA e
      nesta Política de Privacidade, respeitando o período de retenção de dados
      determinado pela legislação aplicável.
      <br />
      Caso você solicite a exclusão de sua conta, as suas informações pessoais
      fornecidas ao E-GORJETA durante a sua utilização dos nossos serviços serão
      excluídos definitivamente sempre que a legislação assim o exigir.
    </p>
    <p class="has-line-data" data-line-start="119" data-line-end="120">
      Em alguns casos, poderemos reter suas informações mesmo que você exclua
      sua conta, tais como nas hipóteses de guarda obrigatória de registros
      previstas na lei aplicável, se houver uma questão não resolvida
      relacionada à sua conta (como, por exemplo, uma reclamação ou disputa não
      resolvida), ou caso seja necessário para nossos interesses comerciais
      legítimos, como prevenção de fraudes e aprimoramento da segurança dos
      nossos usuários.
    </p>
    <p class="has-line-data" data-line-start="121" data-line-end="124">
      <strong>O E-GORJETA transfere os dados para outros países?</strong>
      <br />
      O E-GORJETA poderá realizar transferências internacionais de dados para
      outros países, tais como Estados Unidos da América e para países da União
      Europeia e da América Latina, a fim de realizar algumas das atividades
      envolvidas nos serviços prestados a você, bem como para poder obter
      informações que possam contribuir para o aperfeiçoamento dos nossos
      serviços.
      <br />
      Em qualquer caso de compartilhamento com parceiros localizados em outros
      países, estabelecemos contratualmente que o parceiro possua padrão de
      proteção de dados e segurança da informação compatível com esta política
      de privacidade, a fim de que seus dados sejam sempre protegidos nestes
      termos.
    </p>
    <p class="has-line-data" data-line-start="125" data-line-end="128">
      <strong>Como as informações são compartilhadas?</strong>
      <br />
      Além das finalidades descritas acima, nós podemos também compartilhar com
      terceiros as suas informações coletadas em algumas hipóteses, que
      descreveremos abaixo.
      <br />
      Para outros usuários do E-GORJETA
    </p>
    <p class="has-line-data" data-line-start="129" data-line-end="131">
      Quando você se inscreve nos nossos serviços e submete suas informações
      pessoais para criar um perfil ou coleção visual, outros usuários verão seu
      nome e foto.
      <br />
      Caso você tenha optado por fornecer informações pessoais adicionais em seu
      perfil, os usuários do E-GORJETA também verão essas informações
      adicionais, incluindo, mas não se limitando a, website e informações
      biográficas. Fornecer informação adicional é uma opção sua. Nós
      recomendamos que você proteja e não divulgue na internet nenhuma
      informação que você considere sensível ou confidencial.
    </p>
    <p class="has-line-data" data-line-start="132" data-line-end="134">
      Com empresas do Grupo E-GORJETA
      <br />
      Nós podemos compartilhar os dados que coletamos com as empresas do Grupo
      Econômico ao qual pertence o E-GORJETA (“Grupo do E-GORJETA”).
    </p>
    <p class="has-line-data" data-line-start="135" data-line-end="136">
      As empresas do Grupo do E-GORJETA seguem o mesmo padrão de proteção de
      dados pessoais descrito nesta Política de Privacidade do E-GORJETA e
      obedecem às mesmas finalidades descritas neste documento. Ao
      compartilharmos os seus dados essas empresas, as mesmas garantias e
      cuidados que o E-GORJETA possui com os seus dados serão replicados pelas
      empresas do Grupo do E-GORJETA.
    </p>
    <p class="has-line-data" data-line-start="137" data-line-end="138">
      O compartilhamento de dados com empresas do Grupo do E-GORJETA terá como
      finalidades: (a) o desenvolvimento de novos produtos e serviços; (b) a
      oferta de produtos e serviços que melhor atendam aos seus interesses; ©
      geração de dados estatísticos e agregados acerca do uso de nossos produtos
      e serviços e perfis dos usuários.
    </p>
    <p class="has-line-data" data-line-start="139" data-line-end="141">
      Em alteração de controle societário do E-GORJETA
      <br />
      No caso de operações societárias, como reestruturação, fusão ou venda de
      ativos do E-GORJETA, do grupo econômico ou de parte dele, seus dados
      poderão ser transferidos, desde que respeitados os termos desta política
      de privacidade.
    </p>
    <p class="has-line-data" data-line-start="142" data-line-end="144">
      <strong>Provedores de serviços e outros parceiros</strong>
      <br />
      Por razões de processamento e inteligência em relação aos dados do
      E-GORJETA, poderemos também compartilhar informações com algumas empresas
      ou indivíduos prestadores de serviço ou empresas parceiras do E-GORJETA.
      Nesse caso, os dados serão tratados de forma a proteger a sua privacidade,
      tendo essas empresas dever de proteção compatível com o da política de
      privacidade do E-GORJETA.
    </p>
    <p class="has-line-data" data-line-start="145" data-line-end="146">
      Nós podemos utilizar os serviços dessas empresas para facilitar nosso
      serviço, prover o serviço do E-GORJETA em nosso nome, executar atividades
      relacionadas ao serviço, incluindo – mas não se limitando a –, manutenção
      dos serviços, gerenciamento de banco de dados, análise de dados e melhoria
      de funcionalidades incluindo marketing, ou auxiliar-nos na avaliação de
      como o nosso serviço é usado.
    </p>
    <p class="has-line-data" data-line-start="147" data-line-end="148">
      Em relação ao pagamento de suas compras no nosso Website ou Aplicativo,
      caso opte por pagar diretamente online, nós poderemos compartilhar seus
      dados de pagamento com empresas processadores de pagamento com os quais
      trabalhamos. Esse compartilhamento é feito com o fim exclusivo de
      viabilizar a compra efetivada por você.
    </p>
    <p class="has-line-data" data-line-start="149" data-line-end="150">
      Essas empresas ou indivíduos têm acesso à sua informação pessoal apenas
      para executar atividades solicitadas pelo E-GORJETA e são obrigados a não
      usar ou divulgar tais informações para nenhuma outra finalidade.
    </p>
    <p class="has-line-data" data-line-start="151" data-line-end="153">
      <strong>
        Serviços de redes sociais e outros provedores de aplicação
      </strong>
      <br />
      Nós podemos divulgar informações de sua conta no E-GORJETA (incluindo
      informações pessoais) para outros provedores de aplicação ligados à sua
      conta E-GORJETA. Nós permitimos que você conecte sua conta de serviços de
      redes sociais e outros provedores de aplicação aos serviços do E-GORJETA.
    </p>
    <p class="has-line-data" data-line-start="154" data-line-end="157">
      Dessa forma, você poderá postar atividades recentes em nossos serviços nas
      suas contas de redes sociais. Se você não quiser mais postar suas
      atividades em sua conta de rede social, poderá simplesmente fazer isso ou
      alterar suas preferências quando a opção estiver disponível.
      <br />
      Para publicidade e serviços de análise
      <br />
      Para poder entregar publicidade e promoções adequadas para você, bem como
      aperfeiçoar nossos serviços, nós também podemos compartilhar as suas
      informações com empresas especializadas em marketing e análise de dados
      digitais que ofereçam nível de proteção de dados compatível com esta
      política de privacidade.
    </p>
    <p class="has-line-data" data-line-start="158" data-line-end="160">
      <strong>Como respondemos a requerimentos legais?</strong>
      <br />O E-GORJETA também poderá compartilhar suas informações com
      autoridades policiais ou judiciais, autoridades públicas competentes ou
      outros terceiros, dentro e fora do país em que você reside, caso seja
      requerido pela legislação aplicável, por decisão judicial e por requisição
      de autoridades, ou se necessário para responder a processos judiciais ou
      para participar em eventuais litígios ou disputas de qualquer natureza.
    </p>
    <p class="has-line-data" data-line-start="161" data-line-end="162">
      Nestas situações, o E-GORJETA cooperará com as autoridades competentes na
      medida em que discricionariamente entenda necessário e adequado em relação
      a qualquer investigação de ilícitos, infrações a direitos de propriedade
      industrial ou intelectual, ou outra atividade que seja ilegal ou que possa
      expor o E-GORJETA ou seus usuários a qualquer responsabilidade legal ou
      lhes impor riscos, ressalvadas hipóteses de sigilo de informações
      constantes na legislação aplicável.
    </p>
    <p class="has-line-data" data-line-start="163" data-line-end="165">
      Além disso, o E-GORJETA se reserva o direito de compartilhar informações
      sobre seus usuários a terceiros quando houver motivos suficientes para
      considerar que a atividade de um usuário é suspeita, ilegal ou prejudicial
      ao E-GORJETA ou a terceiros.
      <br />
      Este direito será utilizado pelo E-GORJETA quando considerar apropriado ou
      necessário para manter a integridade e a segurança do seu serviço, para o
      cumprimento dos seus Termos e Condições de Uso e Política de Privacidade,
      e com o intuito de cooperar com a execução e cumprimento da lei,
      independentemente de existir ou não uma ordem judicial ou administrativa
      para tanto.
    </p>
    <p class="has-line-data" data-line-start="166" data-line-end="168">
      <strong>Como protegemos suas informações?</strong>
      <br />O E-GORJETA emprega seus melhores esforços para respeitar e proteger
      suas informações pessoais contra perda, roubo ou qualquer modalidade de
      uso indevido, bem como contra acesso não autorizado, divulgação, alteração
      e destruição.
    </p>
    <p class="has-line-data" data-line-start="169" data-line-end="170">
      Utilizamos o moderno princípio de privacy by design, respeitando a sua
      privacidade e protegendo seus dados nos nossos processos internos como um
      todo.
    </p>
    <p class="has-line-data" data-line-start="171" data-line-end="172">
      Só tratamos os seus dados mediante alto grau de segurança, implementando
      as melhores práticas em uso na indústria para a proteção de dados, tais
      como técnicas de criptografia, monitoração e testes de segurança
      periódicos. Contudo, não é possível garantir completamente a não
      ocorrência de interceptações e violações dos sistemas e bases de dados do
      E-GORJETA, uma vez que a internet possui sua estrutura de segurança em
      permanente aperfeiçoamento.
    </p>
    <p class="has-line-data" data-line-start="173" data-line-end="175">
      <strong>
        Como iremos notificá-lo em caso de mudanças à essa política?
      </strong>
      <br />O E-GORJETA poderá atualizar essa Política de Privacidade
      periodicamente, sendo que a versão em vigor será sempre a mais recente.
    </p>
    <p class="has-line-data" data-line-start="176" data-line-end="178">
      Se fizermos alguma alteração na política em termos materiais, podemos
      colocar um aviso no nosso Website, Aplicativo ou te enviar um e-mail,
      juntamente com a Política de Privacidade atualizada. Por isso é sempre
      importante manter seus dados de contato atualizados.
      <br />
      Para verificar a data da versão em vigor, verifique a “Data de
      atualização” no início desse documento.
    </p>
    <p class="has-line-data" data-line-start="179" data-line-end="181">
      Como entrar em contato com E-GORJETA em caso de dúvidas?
      <br />
      Se você tiver algum questionamento ou dúvida com relação à Política de
      Privacidade do E-GORJETA ou qualquer prática aqui descrita, entre em
      contato conosco no endereço através do e-mail{" "}
      <a href="mailto:privacidade@egorjeta.com.br">
        privacidade@egorjeta.com.br
      </a>
      . Para que sua solicitação seja devidamente analisada por nós, ela deverá
      conter, no mínimo, as seguintes informações:
    </p>
    <ul>
      <li>Nome completo;</li>
      <li>Tipo e número de documento de identificação;</li>
      <li>Telefone para contato;</li>
      <li>E-mail (o mesmo utilizado no cadastro do E-GORJETA);</li>
      <li>Descrição da dúvida ou motivos de eventual solicitação.</li>
    </ul>
  </div>
);
