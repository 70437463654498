import React from "react";
import _ from "styled-components";

export default props => <Title {...props}>{props.children}</Title>;

const Title = _.h1`
  font-family: 'Palanquin Dark', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 3.45rem;
  letter-spacing: -0.16rem;

  @media(max-width: 480px) {
    font-size: 2.5rem;
  }
`;
