import React, { Component } from "react";
import Mask from "vanilla-masker";
import { Contact } from "../models";
import { ContactPage } from "../pages";

const defaultAttributes = { name: "", email: "", phone: "", message: "" };
class ContactController extends Component {
  constructor(props) {
    super(props);
    this.state = {
      params: { ...defaultAttributes },
      notice: "",
      type: "error",
      actions: {
        onChange: this.onChange,
        onSubmit: this.onSubmit
      }
    };
  }

  onChange = event => {
    const { params } = this.state;
    let value = event.target.value;

    if (event.target.name === "phone") {
      const masks = ["(99) 9999-9999", "(99) 99999-9999"];
      value = Mask.toPattern(
        event.target.value,
        value.length > 14 ? masks[1] : masks[0]
      );
    }

    params[event.target.name] = value;
    this.setState({ params });
  };

  onSubmit = event => {
    event.preventDefault();

    this.setState({ busy: true });

    const contact = new Contact(this.state.params);
    contact
      .send()
      .then(() => this.setState({
          notice: 'E-mail enviado com sucesso!',
          type: 'success',
          params: { ...defaultAttributes }
        })
      )
      .catch(err => this.setState({
          notice: err.join(', '),
          type: 'danger'
        })
      )
      .finally(() => this.setState({ busy: false }));
  };

  render = () => <ContactPage {...this.state} {...this.props} />;
}

export default ContactController;
