import React, { useEffect, useState } from "react";
import { FaqPage } from "../pages";
import { Resource } from "../models";

const FaqController = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    (async () => {
      const data = await Resource.faq();
      console.log(data);
      setData(data);
    })();
  }, []);

  return <FaqPage data={data} />;
};

export default FaqController;
